import moment from "moment";
import React, { useState } from "react";
import { CellProps } from "react-table";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spacer from "~/components/Spacer/Spacer";
import Spinner from "~/components/Spinner/Spinner";
import CobuConstants from "~/helpers/cobuConstants";
import styles from './ActiveQueues.module.css';
import useJobs from "~/hooks/queues/useJobs";
import { Job } from "~/types/queues";
import { useParams } from "react-router-dom";

const JobStatus = () => {
    const { queueId } = useParams<{ queueId: string }>();
    const { jobs, loadingConfig, retryJob, retryAllJobs } = useJobs(queueId);

    /* UI Components */

    const jobsTableConfig = [
        {
            Header: 'JOB ID',
            accessor: 'uuid',
            Cell: (cell: CellProps<Job>) => (
                <div style={{ whiteSpace: 'normal' }} className='default-text'>
                    {cell.row.original.uuid}
                </div>
            ),
        },
        {
            Header: 'METADATA',
            Cell: (cell: CellProps<Job>) => (
                <div style={{ whiteSpace: 'normal' }} className='default-text'>
                    {JSON.stringify(cell.row.original.metadata)}
                </div>
            ),
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            Cell: (cell: CellProps<Job>) => {
                const status = cell.row.original.status;
                const statusClass = getStatusClass(status); // Function to determine class

                return (
                    <div className={`${styles.status} ${statusClass}`}>
                        {status.toUpperCase()}
                    </div>
                );
            }
        },
        {
            Header: 'FAILURE REASON',
            accessor: 'failureReason',
            Cell: (cell: CellProps<Job>) => {
                return cell.row.original.status === 'failed' ? (
                    <div className={styles.failureReason}>
                        {cell.row.original.failureReason}
                    </div>
                ) : (
                    <div className={styles.noFailureReason}>-</div> // Placeholder for non-failed jobs
                );
            }
        },
        {
            Header: 'RUN DATE',
            accessor: 'createdAt',
            Cell: (cell: CellProps<Job>) => (
                <div>
                    {moment(cell.row.original.createdAt).local().format('MM/DD/YYYY')}
                </div>
            ),
        },
        {
            Header: 'ACTION',
            id: 'retryJob',
            Cell: (cell: CellProps<Job>) => {
                return cell.row.original.status === 'failed' ? (
                    <button
                        className='btn-primary btn-standard'
                        onClick={() => retryJob(cell.row.original.uuid)}
                    >
                        {CobuConstants.queueManagement.retryJob}
                    </button>
                ) : null
            }
        }
    ];

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'pending':
                return styles.pending;
            case 'processing':
                return styles.processing;
            case 'completed':
                return styles.completed;
            case 'failed':
                return styles.failed;
            default:
                return '';
        }
    };

    const getCobuTableInstance = (data: Job[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={jobsTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
                actions={[retryAllAction()]}
                actionsStyle={{ top: '150px', right: '120px' }}
            />
        );
    };


    const retryAllAction = () => {
        return (
            <button
                className='btn-primary btn-standard bold'
                onClick={() => {
                    retryAllJobs()
                }}
            >
                {CobuConstants.queueManagement.retryAllJob}
            </button>
        );
    };

    if (loadingConfig.flag) return (
        <div className={styles.loadingContainer}>
            <Spinner />
            <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
        </div>
    );

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.queueManagement.title}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(jobs, CobuConstants.queueManagement.noJobs)}
        </div>
    );
}

export default JobStatus;