import React, { useEffect } from 'react';
import {
  Field,
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched
} from 'formik';
import { Building } from '~/types/building';
import { Group } from '~/reducers/groupAdmin';
import { Colors } from '~/enums/Colors';
import * as Yup from 'yup';
import './style.css';
import { EntrataIntegratedBuilding } from '~/api/buildingEntrataIntegration';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export interface AddNewEntrataIntegrationFormValues {
  building: string;
  domain: string;
  username: string;
  password: string;
  propertyId: string;
  default: boolean;
}

const validationSchema = Yup.object().shape({
  building: Yup.string().required('Building is missing or is invalid'),
  propertyId: Yup.string().required('̦Property ID is missing or is invalid'),
  domain: Yup.string().required('Domain is missing or is invalid'),
  username: Yup.string().required('Username is missing or is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'The password should contain atleast 8 characters')
});

export interface AddNewEntrataIntegrationFormValuesErrors
  extends AddNewEntrataIntegrationFormValues {}

type AddEntrataIntegrationFormProps = {
  buildings: Building[];
  selectedIntegration?: EntrataIntegratedBuilding;
  onFormSubmit: (values: AddNewEntrataIntegrationFormValues) => void;
};

const Form = (props: {
  selectedIntegration?: EntrataIntegratedBuilding;
  formikProps: FormikProps<AddNewEntrataIntegrationFormValues>;
  buildings: Building[];
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    handleChange
  } = props.formikProps;
  const { buildingId } = useParams<{ buildingId: string }>();

  useEffect(() => {
    if (props.selectedIntegration) {
      setFieldValue('building', props.selectedIntegration?.buildingId);
      setFieldValue('username', props.selectedIntegration?.username);
      setFieldValue('password', props.selectedIntegration?.password);
      setFieldValue('domain', props.selectedIntegration?.domain);
      setFieldValue('propertyId', props.selectedIntegration?.propertyId);
      setFieldValue('default', props.selectedIntegration?.default);
    } else if (buildingId) {
      setFieldValue('building', buildingId); // for particular building PMS integrations
    }
  }, [props.selectedIntegration]);

  return (
    <form className='group-form' onSubmit={handleSubmit}>
      <div>
        <div className='margin-top-16'>
          <p id='building-label'>Building</p>
          <select
            name='building'
            disabled={!!buildingId || !!props.selectedIntegration}
            value={values.building}
            onChange={handleChange}
            className='margin-top-16'
            style={{ width: '100%' }}
          >
            <option value='' disabled>
              Select a Building
            </option>
            {props.buildings?.map((building) => (
              <option value={building.uuid} key={building.uuid}>
                {building.name}
              </option>
            ))}
          </select>

          <p style={{ color: Colors.cobuRed }} className='interestErrorTag'>
            {touched.building && Boolean(errors.building)
              ? errors.building
              : null}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='margin-top-16'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '45%'
            }}
          >
            <label>Entrata Property ID</label>
            <input
              id='propertyId'
              name='propertyId'
              onChange={handleChange}
              onBlur={() => setFieldTouched('propertyId', true, false)}
              value={values.propertyId}
            />
            {touched.propertyId && Boolean(errors.propertyId) && (
              <p style={{ color: Colors.cobuRed }}>{errors.propertyId}</p>
            )}
          </div>
          <div
            className='margin-top-16'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '45%'
            }}
          >
            <label>Entrata Domain</label>
            <input
              id='domain'
              name='domain'
              onChange={handleChange}
              onBlur={() => setFieldTouched('domain', true, false)}
              value={values.domain}
            />
            {touched.domain && Boolean(errors.domain) && (
              <p style={{ color: Colors.cobuRed }}>{errors.domain}</p>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className='margin-top-16'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '45%'
            }}
          >
            <label>Entrata Username</label>
            <input
              id='username'
              name='username'
              onChange={handleChange}
              onBlur={() => setFieldTouched('username', true, false)}
              value={values.username}
            />
            {touched.username && Boolean(errors.username) && (
              <p style={{ color: Colors.cobuRed }}>{errors.username}</p>
            )}
          </div>
          <div
            className='margin-top-16'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '45%'
            }}
          >
            <label>Entrata Password</label>
            <input
              id='password'
              name='password'
              type='password'
              onChange={handleChange}
              onBlur={() => setFieldTouched('password', true, false)}
              value={values.password}
            />
            {touched.password && Boolean(errors.password) && (
              <p style={{ color: Colors.cobuRed }}>{errors.password}</p>
            )}
          </div>
        </div>
        <div className='margin-top-16 flex-row align-center'>
          <input
            id='default'
            name='default'
            type='checkbox'
            onChange={handleChange}
            checked={values.default}
          />
          <label>Make default</label>
        </div>
      </div>
      <div id='buttons-wrapper'>
        <button
          type='submit'
          className='btn-standard btn-secondary margin-right-16'
        >
          Cancel
        </button>
        <button type='submit' className='btn-standard btn-primary'>
          Save
        </button>
      </div>
    </form>
  );
};

const AddEntrataIntegrationForm = (props: AddEntrataIntegrationFormProps) => {
  const { onFormSubmit } = props;

  const buildings = Object.values(props.buildings);

  return (
    <Formik
      initialValues={{
        building: '',
        domain: '',
        username: '',
        password: '',
        propertyId: '',
        default: false
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps: FormikProps<AddNewEntrataIntegrationFormValues>) => {
        return (
          <Form
            selectedIntegration={props.selectedIntegration}
            formikProps={formikProps}
            buildings={buildings}
          />
        );
      }}
    </Formik>
  );
};

export default AddEntrataIntegrationForm;
