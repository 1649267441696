import React from 'react';
import { YardiCredential } from '~/api/yardiIntegration';

export const YardiCredentialTableColumns = ({
  onDeleteYardiCredentialsClicked
}: {
  onDeleteYardiCredentialsClicked: (credentialId: string) => void;
}) => [
  {
    Header: 'NAME',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.name}</div>
  },
  {
    Header: 'USERNAME',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.username ?? 'N/A'}</div>
  },
  {
    Header: 'PASSWORD',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.password ?? 'N/A'}</div>
  },
  {
    Header: 'DATABASE',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.database ?? 'N/A'}</div>
  },
  {
    Header: 'SERVER NAME',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.serverName ?? 'N/A'}</div>
  },
  {
    Header: 'WEB SERVICE URL',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.webServiceUrl ?? 'N/A'}</div>
  },
  {
    Header: 'CREATED ON',
    disableSortBy: true,
    accessor: (row: YardiCredential) => <div>{row.createdAt ?? 'N/A'}</div>
  },
  {
    Header: 'ACTIONS',
    disableSortBy: true,
    accessor: (row: YardiCredential) => (
      <div>
        <button
          className='btn btn-primary btn-small'
          onClick={() => onDeleteYardiCredentialsClicked(row.uuid!)}
        >
          DELETE
        </button>
      </div>
    )
  }
];
