import { emailRegex, nameRegex } from '~/helpers/helpers';
import { FormInputConfig } from '~/types/residentManagement';

export const landingPageFormConfigV1: FormInputConfig[] = [
  {
    id: 'firstName',
    isRequired: true,
    placeholder: 'First Name',
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        color: 'white'
      }
    }
  },
  {
    placeholder: 'Last Name',
    id: 'lastName',
    isRequired: true,
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  {
    id: 'email',
    isRequired: true,
    placeholder: 'Email',
    pattern: emailRegex(),
    patternErrorMsg: 'Oops! Please enter a valid email',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  {
    id: 'phoneNumber',
    isRequired: true,
    placeholder: 'Phone Number',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  }
];
