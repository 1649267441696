import { AxiosResponse } from 'axios';
import {
  IReferralConfigSetting,
  IReferralSettingEntrataPMS,
  IReferralSettingForm,
  IReferralSettingYardiPMS
} from '~/containers/Referral/interfaces';
import { emperor } from '~/services';
import { Building } from '~/types/building';
import { UserMin } from '~/types/user';
import {
  addNewEntrataIntegration,
  updateBuildingEntrataIntegration
} from './buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';

interface IReferralDetails {
  logoBackgroundColor?: string;
}

export interface IReferralPageOption {
  uuid: string;
  name: string;
  details: JSON;
}

export interface IReferralOffer {
  uuid: string;
  building: string;
  residentPayout: string;
  cobuPayout: string;
  referralStatus: string;
  payoutType: string;
  referralLandingPageOption: IReferralPageOption | string;
  title: string;
  bannerTitle: string;
  description: string;
  rulesAndEligibility: string;
  images: string[];
  startDate: string;
  endDate: string;
  landingpageIntroParagraph: string;
  details?: IReferralDetails;
  landingPageTitleAction?: string;
  landingPageLocationBlurb?: string;
  popupTitle?: string;
  popupDescription?: string;
  landingPageFormButton?: string;
  landingPageCongratsDescription?: string;
  landingPageCongratsTitle?: string;
  landingPageCongratsNext?: string;
  incentiveType?: string;
}

export interface IReferralLead {
  buildingId: string;
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  leadReferredBy: string;
  referral_offer: string;
  confirmationEmail?: boolean;
}

export interface ICommunityLead {
  uuid: string;
  referredBy: string;
  roomNumber: string;
  createdAt: string;
  leadName: string;
  leadEmail: string;
  status: string;
}

export interface IReferralHelloData {
  address: string;
  building_phone_number: string;
  review_analysis: {
    avg_score: number;
    count_reviews: string;
  };
  building_availability: {
    bed: number;
    min_price: number;
    price: number;
  }[];
}

export interface IReferralFormData {
  referralOffer: IReferralOffer;
  referredUser: UserMin;
  building: Building;
  helloData: IReferralHelloData;
  avgReviewRating?: { avgReviewRating: string; totalReviews: string };
}

export interface IPayoutTypes {
  uuid: string;
  name: string;
}
export interface IReferralStatus {
  uuid: string;
  name: string;
}
export interface IIncentiveType {
  uuid: string;
  name: string;
}

export interface IReferralExternalEmail {
  uuid: string;
  building: string;
  email: string;
}

export const postReferralLead = async (
  payload: IReferralLead,
  referralLandingPage: string
): Promise<AxiosResponse<IReferralLead>> => {
  return await emperor.post('/referral/create-referral-lead', {
    ...payload,
    referralLandingPage
  });
};

export const getReferralOffer = async (
  buildingId: string
): Promise<AxiosResponse<IReferralOffer>> => {
  return await emperor.get('/referral/referral-offer', {
    params: { buildingId }
  });
};

export const postReferralOffer = async (
  payload: FormData
): Promise<AxiosResponse<IReferralOffer>> => {
  return await emperor.post('/referral/create-referral-offer', payload);
};

export const getPayoutOptions = async (): Promise<
  AxiosResponse<IPayoutTypes[]>
> => {
  return await emperor.get('/referral/get-payout-types');
};

export const getIncentiveOptions = async (): Promise<
  AxiosResponse<IIncentiveType[]>
> => {
  return await emperor.get('/referral/incentive-options');
};

export const getReferralStatusOptions = async (): Promise<
  AxiosResponse<IReferralStatus[]>
> => {
  return await emperor.get('/referral/get-referral-status');
};

export const getReferralLeads = async (
  buildingId: string
): Promise<AxiosResponse<ICommunityLead[]>> => {
  return await emperor.get('/referral/referral-leads', {
    params: { buildingId }
  });
};

export const getReferralFormData = async (
  referralCode: string
): Promise<AxiosResponse<IReferralFormData>> => {
  return await emperor.get('/referral/get-referral-form', {
    params: { referralCode }
  });
};

export const postCommunityLeadSetting = async (
  buildingId: string,
  settings: IReferralConfigSetting
): Promise<void> => {
  return await emperor.post('/referral/community-lead-setting', {
    buildingId,
    ...settings
  });
};

export const postReferralYardiIntegration = async (payload: {
  buildingId: string;
  agentName?: string;
  credentials?: string;
  leadSourceName?: string;
  yardiVersion?: string;
  settings: IReferralSettingYardiPMS;
}): Promise<void> => {
  const {
    agentName,
    settings,
    credentials,
    leadSourceName,
    buildingId,
    yardiVersion
  } = payload;

  return await emperor.put('put-yardi-integration', {
    ...(settings.yardiUuid ? { uuid: settings.yardiUuid } : {}),
    building: buildingId,
    yardi_property_id: settings.yardi_property_id,
    leadSourceId: settings.yardiLeadSourceId,
    credentials: credentials,
    default: true,
    details: {
      agentName,
      leadSourceName,
      yardiVersion
    },
    unmarkOtherDefault: true
  });
};

export const postReferralEntrataIntegration = async (
  buildingId: string,
  integration: IReferralSettingEntrataPMS,
  enableSlackNotification = false
): Promise<void> => {
  if (integration.entrataUuid) {
    await updateBuildingEntrataIntegration({
      ...integration,
      uuid: integration.entrataUuid,
      building: buildingId,
      leadSourceId: integration.entrataLeadSourceId,
      default: true,
      unmarkOtherDefault: true
    });

    return;
  }

  await addNewEntrataIntegration(
    convertKeyValueToFormData({
      domain: integration.entrata_domain,
      propertyId: integration.entrata_property_id,
      username: integration.entrata_username,
      password: integration.entrata_password,
      leadSourceId: integration.entrataLeadSourceId,
      building: buildingId,
      default: true,
      unmarkOtherDefault: true,
      enableSlackNotification
    })
  );
};

export const postReferralExternalEmails = async (
  buildingId: string,
  emails: string
): Promise<void> => {
  return await emperor.put('/put-referral-external-emails', {
    buildingUuid: buildingId,
    emails
  });
};

export const getReferralExternalEmails = async (
  buildingId: string
): Promise<AxiosResponse<IReferralExternalEmail>> => {
  return await emperor.get('/get-referral-external-emails', {
    params: { buildingUuid: buildingId }
  });
};

export const getLandingPageOptions = async (): Promise<
  AxiosResponse<IPayoutTypes[]>
> => {
  return await emperor.get('/referral/landing-page-options');
};
