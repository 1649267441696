import React, { useEffect, useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import { RootState } from '~/redux/store';
import GatheringForm from '~/components/GatheringForm/GatheringForm';

import { GatheringFormType, GatheringType } from '~/types/gathering';
import { fetchOneGatheringSuccess } from '~/reducers/residentGathering';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import classes from './GatheringFormContainer.module.css';
import { FormActions } from '~/enums/FormActions';
import { toast } from 'react-toastify';
import { PostDataResult } from '~/types/postDataResult';
import Spinner from '~/components/Spinner/Spinner';
import { Mixpanel } from '~/services/MixpanelService';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import {
  createGatheringRequest,
  fetchBuildingDetails,
  updateGathering
} from '~/api/building';
import { fetchOneGatheringRequest } from '~/api/residentGathering';
import { useQuery } from 'react-query';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { ApiState } from '~/types/common';
import _, { remove } from 'lodash';
import { current } from '@reduxjs/toolkit';
import GenericModal from '~/components/GenericModal/GenericModal';

interface DispatchProps {
  fetchOneGatheringSuccess: typeof fetchOneGatheringSuccess;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const CreateGatheringContainer = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { loader } = useFullScreenLoader();

  const { buildingId, gatheringId } = useParams<{
    buildingId: string;
    gatheringId: string;
  }>();
  const { buildings } = props;
  const [formAction, setFormAction] = useState(FormActions.Create);
  const [buildingUserOptions, setBuildingUserOptions] = useState([]);
  const [template] = useState(location.state && location.state.template);
  const [gatheringChangesToSave, setgatheringChangesToSave] =
    useState<Partial<GatheringFormType>>();
  const crumbsIndexes = gatheringId ? [3] : [];
  const [gathering, setGathering] = useState<GatheringType>();
  const [removedCustomDates, setremovedCustomDates] = useState<string[]>([]);
  const [newExceptionDates, setnewExceptionDates] = useState<string[]>([]);
  const [datesToDeleteRsvps, setdatesToDeleteRsvps] = useState<string[]>([]);
  const [showDeleteRsvpWarning, setshowDeleteRsvpWarning] =
    useState<boolean>(false);
  const [getGatheringApiState, setgetGatheringApiState] =
    useState<ApiState>('IDLE');

  const fromTemplate = location.state?.fromTemplate ?? false;

  const getGathering = () => {
    if (!gatheringId) return;

    loader.show();

    setgetGatheringApiState('LOADING');

    fetchOneGatheringRequest(gatheringId)
      .then((res) => setGathering(res.data))
      .then(() => setgetGatheringApiState('SUCCESS'))
      .finally(() => {
        loader.hide();
      });
  };

  useEffect(() => {
    getGathering();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/edit')) setFormAction(FormActions.Edit);
    else if (location.pathname.includes('/duplicate'))
      setFormAction(FormActions.Duplicate);
    else setFormAction(FormActions.Create);

    fetchBuildingDetails(buildingId)
      .then((res) => {
        const activeResidents = res.data.residents.filter(
          (resident: { deactivated: boolean }) => !resident.deactivated
        );
        const buildingUserOptions = activeResidents.map(
          (resident: { email: string }) => {
            return { value: resident.email, label: resident.email };
          }
        );
        setBuildingUserOptions(buildingUserOptions);
      })
      .catch(() => {
        toast('Oh no! Something went wrong, please try again later');
      })
      .finally(() => {
        loader.hide();
      });
  }, [location]);

  const selectedBuilding = getBuildingFromStore(buildingId);

  const getCustomDatesRemoved = (data: Partial<GatheringFormType>) => {
    const original =
      gathering?.eventCustomDates?.map((el) => el.eventDatetime) ?? [];
    const current = data.eventCustomDates ?? [];

    const removedDates = _.differenceBy(original, current);

    return removedDates;
  };

  const getExceptionDatesAdded = (data: Partial<GatheringFormType>) => {
    const original =
      gathering?.eventExceptionDates?.map((el) => el.exceptionDate) ?? [];
    const current = data.eventExceptionDates?.map((el) => el.date) ?? [];

    const datesAdded = _.differenceBy(current, original);

    return datesAdded;
  };

  const canShowConfirmation = (data: Partial<GatheringFormType>) => {
    const d1 = getCustomDatesRemoved(data);
    const d2 = getExceptionDatesAdded(data);

    setremovedCustomDates(d1);
    setnewExceptionDates(d2);

    setdatesToDeleteRsvps([...d1, ...d2]);

    return d1.length > 0 || d2.length > 0;
  };

  const createGathering = async (
    gathering: Partial<GatheringFormType>
  ): Promise<PostDataResult<GatheringFormType>> => {
    loader.show();

    return new Promise((resolve) => {
      if (typeof gathering.image === 'string') {
        gathering.imageUrlForDuplicate = gathering.image;
      }

      createGatheringRequest(gathering as GatheringFormType)
        .then((res) => {
          Mixpanel.track('did_create_gathering', {
            $gathering_name: gathering.title,
            $gathering_date: gathering.startTime,
            $building_name: selectedBuilding.name,
            ...(fromTemplate && { $template_name: template.title })
          });

          if (formAction === FormActions.Duplicate) {
            Mixpanel.track('did_duplicate_gathering', {
              $gathering_name: gathering.title
            });
          }

          resolve({ success: true });
          history.push(`/building/${res.data.group.building}/gathering`);
        })
        .catch((error) => {
          if (error.status === 404) {
            toast(
              "Creator email or group provided didnt't match any data in the building."
            );
            resolve({ success: false });
          }
        })
        .finally(() => {
          loader.hide();
        });
    });
  };

  const saveGatheringChanges = async (
    gathering: Partial<GatheringFormType>
  ): Promise<PostDataResult<GatheringFormType>> => {
    loader.show();
    gathering.uuid = gatheringId;
    return new Promise((resolve) => {
      updateGathering(gathering as GatheringFormType)
        .then((res) => {
          Mixpanel.track('did_edit_gathering', {
            $gathering_name: gathering.title
          });

          history.push(`/building/${buildingId}/gathering`);
          setnewExceptionDates([]);
          setremovedCustomDates([]);
          resolve({ success: true });
        })
        .catch((error) => {
          if (error.status === 404) {
            toast(
              "Creator email or group provided didnt't match any data in the building."
            );
            resolve({ success: false });
          }
        })
        .finally(() => {
          loader.hide();
        });
    });
  };

  const getConfirmationModalTitle = () => {
    if (removedCustomDates.length > 0 && newExceptionDates.length > 0) {
      return 'Are you sure you want to modify custom dates and exception dates ?';
    }

    if (removedCustomDates.length > 0)
      return 'Are you sure you want to remove custom dates ?';

    if (newExceptionDates.length > 0) {
      return 'Are you sure you want to add exception dates ?';
    }

    return 'Are you sure ?';
  };

  const onSubmit = (data: GatheringFormType) => {
    let gathering: Partial<GatheringFormType> = data;

    const startTimeInTimezone = moment
      .tz(gathering.startTime, selectedBuilding.timezone)
      .format();

    delete gathering.building;

    delete gathering.customEventDatetime;
    delete gathering.eventExceptionDate;
    delete gathering.eventExceptionDateNote;

    gathering.startTime = startTimeInTimezone;

    if (gathering.endTime) {
      const endTimeInTimezone = moment
        .tz(gathering.endTime, selectedBuilding.timezone)
        .format();

      gathering.endTime = endTimeInTimezone;
    } else {
      delete gathering.endTime;
    }

    if (!gathering.eventType) {
      delete gathering.eventType;
    }

    if (
      formAction === FormActions.Create ||
      formAction === FormActions.Duplicate
    ) {
      createGathering(gathering);
    } else {
      if (canShowConfirmation(gathering)) {
        setgatheringChangesToSave(gathering);
        setshowDeleteRsvpWarning(true);
      } else {
        saveGatheringChanges(gathering);
      }
    }
  };

  const onConfirmed = () => {
    if (gatheringChangesToSave) {
      saveGatheringChanges(gatheringChangesToSave);
    }
  };

  useEffect(() => {
    setdatesToDeleteRsvps(
      _.uniq([...newExceptionDates, ...removedCustomDates])
    );
  }, [newExceptionDates, removedCustomDates]);

  return (
    <div>
      <GenericModal
        showModal={showDeleteRsvpWarning}
        title={getConfirmationModalTitle()}
        actionFunction={onConfirmed}
        actionText={'Confirm'}
        onChange={(value) => setshowDeleteRsvpWarning(false)}
        content={
          <div>
            If you confirm, all the RSVPs for the following dates would be
            deleted forever
            <ul className='margin-top-16'>
              {datesToDeleteRsvps.map((el) => (
                <li className='margin-top-8'>
                  {moment(el).format('ddd, DD MMM YYYY')}
                </li>
              ))}
            </ul>
          </div>
        }
      />
      {getGatheringApiState != 'LOADING' && selectedBuilding && (
        <div className={classes.container}>
          <div className={classes.crumbContainer}>
            <Breadcrumbs indexesToHide={crumbsIndexes} />
          </div>
          <GatheringForm
            buildings={buildings}
            onSubmit={onSubmit}
            formAction={formAction}
            gathering={
              getGatheringApiState == 'SUCCESS' && gathering
                ? gathering
                : template
                ? template
                : null
            }
            buildingUserOptions={buildingUserOptions}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings
  };
}

export default connect(mapStateToProps)(
  withFullScreenLoader(CreateGatheringContainer)
);
