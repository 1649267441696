import React, { useEffect, useState } from 'react';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import styles from './index.module.css';
import Spacer from '~/components/Spacer/Spacer';
import { useHistory } from 'react-router-dom';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { ApiState, ViewState } from '~/types/common';
import './style.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import { AddYardiCredentialFormValues } from './NewYardiCredentialsForm';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import {
  fetchYardiCredentials,
  YardiCredential,
  addNewYardiCredentials,
  deleteYardiCredentials
} from '~/api/yardiIntegration';
import { YardiCredentialTableColumns } from './yardiCredentialsTableColumns';
import AddYardiCredentialsForm from './NewYardiCredentialsForm';
import { randomUUID } from 'crypto';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';
import { Roles } from '~/enums/Roles';
import NotFound from '~/components/NotFound/NotFound';

type IntegrationTypes = 'entrata' | 'yardi';

const YardiCredentialsContainer = () => {
  const { loader } = useFullScreenLoader();
  const [getYardiCredentialsApiState, setgetYardiCredentialsApiState] =
    useState<ApiState>('LOADING');
  const [yardiCredentials, setYardiCredentials] = useState<YardiCredential[]>(
    []
  );
  const [addYardiCredentialsViewState, setaddYardiCredentialsViewState] =
    useState<ViewState>('HIDDEN');
  const history = useHistory();

  /* UI Components */

  const getYardiCredentials = () => {
    loader.show();
    fetchYardiCredentials()
      .then((res) => {
        setgetYardiCredentialsApiState('COMPLETED');
        setYardiCredentials(res.data);
      })
      .finally(loader.hide);
  };

  const openAddCredentialsModal = () =>
    setaddYardiCredentialsViewState('VISIBLE');
  const closeAddCredentialsModal = () =>
    setaddYardiCredentialsViewState('HIDDEN');

  const { userRole } = useSelector((state: RootState) => ({
    userRole: state.auth.userRole
  }));

  const isSuperAdminOrVcm = userRole == Roles.Superadmin || Roles.Vcm;

  const onAddYardiCredentialsClicked = (
    values: AddYardiCredentialFormValues
  ) => {
    const newCredentials = new FormData();

    const webServiceUrl = values.webServiceUrl.replace('http://', 'https://');

    newCredentials.append('name', values.name as string);
    newCredentials.append('username', values.username as string);
    newCredentials.append('password', values.password as string);
    newCredentials.append('serverName', values.serverName as string);
    newCredentials.append('database', values.database as string);
    newCredentials.append('webServiceUrl', webServiceUrl as string);

    loader.show();

    addNewYardiCredentials(newCredentials)
      .then((res) => {
        toast('Credential successfully added');
        closeAddCredentialsModal();
        getYardiCredentials();
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast(
          error.message ||
            'Something went wrong, while adding credentials. Please try again later'
        );
      })
      .finally(() => {
        loader.hide();
      });
  };

  const onDeleteYardiCredentialsClicked = (credentialId: string) => {
    if (!credentialId || credentialId.length == 0) return;
    loader.show();

    deleteYardiCredentials(credentialId)
      .then((res) => {
        getYardiCredentials();
        toast('Credential deleted');
      })
      .catch((e: any) => {
        const error = e as AxiosError<{ status: number }>;

        if (e.status == 500 && !e.message) {
          toast(
            'Something went wrong. Credentials cannot be deleted if, "it is being used by an integration"'
          );
        } else {
          toast(
            error.message ||
              'Something went wrong while deleting credential. Please contact support'
          );
        }
      })
      .finally(() => {
        loader.hide();
      });
  };

  const displayAddCredentialsForm = () => {
    if (addYardiCredentialsViewState != 'VISIBLE') return false;

    return (
      <GenericModal
        title='Add Yardi Credential'
        showModal={true}
        onChange={closeAddCredentialsModal}
        content={
          <AddYardiCredentialsForm
            onFormSubmit={onAddYardiCredentialsClicked}
            onClose={closeAddCredentialsModal}
          />
        }
        hideActionButton
        canceActionButton={true}
      ></GenericModal>
    );
  };

  const displayYardiCredentialsList = () => {
    return (
      <div className='building-integration-table'>
        <CobuTableAdvanced
          columns={YardiCredentialTableColumns({
            onDeleteYardiCredentialsClicked
          })}
          data={yardiCredentials}
          noData={{ label: 'No yardi credential found' }}
          // actions={[createPostAction()]}
        />
      </div>
    );
  };

  useEffect(() => {
    getYardiCredentials();
  }, []);

  if (getYardiCredentialsApiState == 'LOADING')
    return (
      <div className={styles.loadingContainer}>
        <h3 style={{ textAlign: 'center', marginTop: '30vh' }}>
          Loading Yardi Credentials
        </h3>
      </div>
    );

  if (!isSuperAdminOrVcm) {
    return <NotFound />;
  }

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <Spacer height={20} />
      {displayAddCredentialsForm()}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 className='text-capitalize'>Yardi Credentials</h1>
        <button
          className='btn btn-primary btn-small'
          onClick={openAddCredentialsModal}
        >
          Add New Credentials
        </button>
      </div>
      <Spacer height={20} />
      {displayYardiCredentialsList()}
    </div>
  );
};

export default withFullScreenLoader(YardiCredentialsContainer);
