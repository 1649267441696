import React from 'react';
import { FormField } from '~/components/FormField';
import Spinner from '~/components/Spinner/Spinner';
import { FormInputConfig } from '~/types/residentManagement';
import { ICreateFlyerFormData } from './interfaces';
import useCreateReferralOffer from '~/hooks/building/referral/useCreateReferralOffer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import './CreateEditReferralForm.css';

export const CreateEditReferralForm = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const {
    loader,
    referralOffer,
    postReferralData,
    createReferralFormConfig,
    register,
    handleSubmit,
    errors,
    control,
    isDirty,
    selectedReferralOptionName,
    pageOptionBasedFormFileds
  } = useCreateReferralOffer(buildingId);

  const onSubmit = (formData: ICreateFlyerFormData) => {
    if (!isDirty) {
      toast('Please edit the form');

      return;
    }

    postReferralData(formData);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='create-referral-item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='container'>
      <Breadcrumbs />
      <div className='float-spinner-parent'>
        {loader && <Spinner float />}
        <Spacer height={24} />
        <div className='title bold'>
          {referralOffer ? 'Update Referral Offer:' : 'Create Referral Offer:'}
        </div>
        <Spacer height={24} />
        <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
          <div className='create-referral-grid'>
            {createReferralFormConfig.map((field) => generateField(field))}
          </div>
          {pageOptionBasedFormFileds && (
            <>
              <Spacer height={20} />
              <h2>{`Selected landing page option "${selectedReferralOptionName}" Form Fields:`}</h2>
              <Spacer height={20} />
              <div className='create-referral-grid'>
                {pageOptionBasedFormFileds.map((field) => generateField(field))}
              </div>
            </>
          )}
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {referralOffer ? 'Update' : 'Create'}
          </button>
        </form>
      </div>
    </div>
  );
};
