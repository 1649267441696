import { emperor } from '~/services';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import { toast } from 'react-toastify';
import { YardiLeaseJsonToCSV } from '~/services/EntrataLeaseJsonToCSV';
import { AxiosResponse } from 'axios';
import { IYardiCredentialPMS } from '~/containers/Referral/interfaces';

export interface YardiBuildingIntegration {
  uuid: string;
  buildingId: string;
  buildingName: string;
  propertyId: string;
  yardi_property_id: string;
  ownerGroup: string;
  propertyOwnerGroup: string;
  lastSyncStatus: 'SUCCESS' | 'FAILED';
  lastSyncTime: Date;
  totalRecordsSynced: number;
  leadSourceId?: string;
  default: boolean;
  credentials: YardiCredential;
}

export interface YardiCredential {
  uuid?: string;
  name: string;
  username: string;
  password: string;
  database: string;
  serverName: string;
  webServiceUrl: string;
  details?: any;
  createdAt?: Date;
  updatedAt?: Date;
}

export type UpdateYardiIntegrationPayload = {
  yardi_property_id: string;
  building?: string;
  uuid?: string;
  default?: boolean;
  leadSourceId?: string;
};

export type YardiProperty = {
  Code: string;
  MarketingName: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  City: string;
  State: string;
  PostalCode: number;
  AccountsReceivable: string;
  AccountsPayable: string;
  OperatingMonth: string;
  credentialsId: string;
  credentials: YardiCredential;
  yardiVersion: string;
};

export const fetchYardiProperties = async () => {
  return emperor.get<{ properties: YardiProperty[] }>('/yardi/properties');
};

export const getYardiIntegratedBuildings = async () => {
  return emperor.get<{ integrations: YardiBuildingIntegration[] }>(
    '/yardi/integrations'
  );
};

export const addNewYardiIntegration = async (payload: FormData) => {
  return emperor.put(`/yardi/integrations`, payload);
};

export const fetchYardiCredentials = async () => {
  return emperor.get<YardiCredential[]>('/yardi/credentials');
};

export const addNewYardiCredentials = async (payload: FormData) => {
  return emperor.put<YardiCredential[]>('/yardi/credentials', payload);
};

export const deleteYardiCredentials = async (credentialsId: string) => {
  return emperor.delete<YardiCredential[]>(
    `/yardi/credentials/${credentialsId}`
  );
};

// BuildingEntrataIntegration
export const updateBuildingYardiIntegration = async (
  integration: UpdateYardiIntegrationPayload
) => {
  const data = convertKeyValueToFormData(integration);
  return emperor.patch(`yardibuildingintegration/${integration.uuid}`, data);
};

export const deleteYardiBuildingIntegration = async (integrationId: string) => {
  return emperor.delete(`yardibuildingintegration/${integrationId}`);
};

export const fetchYardiIntegrationForBuilding = async (buildingId: string) => {
  return emperor.get(`yardibuildingintegration?building=${buildingId}`);
};

export const fetchYardiPropertyAgentsAndSources = async (payload: {
  propertyId: string;
  credentials?: string;
}) => {
  return emperor.get<{ agents: string[]; sources: string[] }>(
    `/yardi/agents-and-sources`,
    {
      params: payload
    }
  );
};

export const getYardiRentroll = async (buildingId: string) => {
  const response = await emperor.get(`/yardi/rentroll/${buildingId}`);

  if (response.status === 200) {
    let lease: any[] = [];

    const buildingName = response?.data?.building.name;
    const buildingLink = response?.data?.building.link;
    const buildingCode = response?.data?.building.code;

    const csvStr = YardiLeaseJsonToCSV(
      response?.data?.residents,
      buildingName,
      buildingId,
      buildingLink,
      buildingCode
    );

    // download CSV
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${buildingName}.csv`;
    hiddenElement.click();
  } else {
    toast('There was an error trying to download the entrata data!');
  }
};

export const postYardiCreds = (
  buildingUuid: string,
  creds: IYardiCredentialPMS
): Promise<AxiosResponse<any>> => {
  return emperor.put('/yardi/cred', { buildingUuid, ...creds });
};

export const verifyYardiCredentials = (
  creds: IYardiCredentialPMS
): Promise<AxiosResponse<{ message: string }>> => {
  return emperor.post('/yardi/cred/verify', creds);
};
